'use strict';

(function () {
  var self;
  class AddEditContactModalCtrl {
    constructor($scope, $uibModalInstance, Site, User, Util, Organization, options, usSpinnerService, Contact) {
      this.Util = Util;
      this.Site = Site;
      this.User = User;
      this.Contact = Contact;
      this.Organization = Organization;
      this.usSpinnerService = usSpinnerService;
      this.$uibModalInstance = $uibModalInstance;
      this.isDisabled = false;
      this.errors = {};
      this.options = options;
      this.submitted = false;

      self = this;
        console.log(this.options.contact);
      if (this.options.contact) {
        this.contact = angular.copy(this.options.contact);
        if(this.contact && this.contact.salutation) {
          this.contact.salutation = this.contact.salutation.trim();
        }
        this.mode = 'UPDATE';
      } else {
        this.mode = 'CREATE';
        this.contact = {};
      }
      if(this.options.organization) {
        this.organization = angular.copy(this.options.organization);
      }

      $scope.$on('$stateChangeStart', function () {
        $uibModalInstance.dismiss();
      });

      this.salutationTypes = [
        'Mr', 'Mrs', 'Ms'
      ];
    }

    saveContact(form) {
        this.submitted = true;
        this.serverError = null;
        if (!this.contact.associatedWith) {
          this.errors = {
            associatedWith: 'Select contact associated with'
          };
          return;
        }
        if (form.$valid) {
            this.isDisabled = true;
            this.startSpin('spinner-1');
            if (this.mode === 'CREATE') {
                this.contact.organization = this.organization.id;
                this.Contact.save(this.contact).$promise
                .then(contact => {
                    this.stopSpin('spinner-1');
                    console.log(contact);
                    this.$uibModalInstance.close(contact);
                })
                .catch(err => {
                    this.isDisabled = false;
                    this.errors = {};
                    this.stopSpin('spinner-1');
                    this.handleError(err, form);
                });
            } else {
                this.contact.organization = this.organization.id;
                this.Contact.update(this.contact).$promise
                .then(contact => {
                    this.stopSpin('spinner-1');
                    console.log(contact);
                    this.$uibModalInstance.close(contact);
                })
                .catch(err => {
                    this.isDisabled = false;
                    this.errors = {};
                    this.stopSpin('spinner-1');
                    this.handleError(err, form);
                });
            }
        }
    }

    handleError(err, form) {
        if (err && err.data && err.data.meta && err.data.meta.error_message) {
          if (err.data.meta.code && err.data.meta.code != 422 && !this.Util.isArray(err.data.meta.error_message)) {
            this.serverError = err.data.meta.error_message || 'Internal Server Error';
          } else {
            err.data.meta.error_message.forEach(errorMesssage => {
              for (let fieldName in errorMesssage) {
                if (form[fieldName]) {
                  form[fieldName].$setValidity('mongoose', false);
                  this.errors[fieldName] = errorMesssage[fieldName];
                }
              }
            })
          }
        } else if (err.data && err.data.message) {
          this.serverError = err.data.message;  
          console.log('validation err: 1', err);
        } else {
          this.serverError = err.data.msg || 'Internal Server Error';
          console.log('validation err: 2', err);
        }
      }

    cancelSave() {
      this.$uibModalInstance.dismiss('cancel');
    }

    startSpin(spinner) {
      this.usSpinnerService.spin(spinner);
    }

    stopSpin(spinner) {
      this.usSpinnerService.stop(spinner);
    }
  }

  angular.module('windmanagerApp')
    .controller('AddEditContactModalCtrl', AddEditContactModalCtrl);
})();
